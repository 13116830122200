import styled from '@emotion/styled';
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Video from '../../panels/Video';
import Heart from '../../../images/heart.svg'
import EmptyHeart from '../../../images/empty-heart.svg'
import useFavoriteProducts from '../../../hooks/useFavoriteProducts'
import Link from "../../Link";
import useEmblaCarousel from 'embla-carousel-react';
import { PrevButton, NextButton, EmBlaCarouselArrowButtons } from "../../panels/Button/EmblaCarouselArrowButtons";

const DisplayImageConatiner = styled.div`
  background: #FFFFFF;
  ${(props) => (props.selected ? 'opacity: 1;' : 'opacity: 0.6;')}
`
const DisplayImageContainer = styled.div`
  background: #FFFFFF;
  margin: 12px 0;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  margin-top: 0px;
  @media (max-width: 763px) {
    display: none;
  }
`
const DisplayImage = styled(GatsbyImage)`
  height: 48px;
  width: 48px;
  margin: 4px;
  cursor: pointer;
  mix-blend-mode: darken;
  background: #FFFFFF;
  img {
    opacity: 1 !important; /* Override the default opacity */
  }
}
`
const DisplayVideo = styled(Video)`
  height: 48px;
  width: 48px;
  margin: 4px;
  cursor: pointer;
`
const ImageWrapper = styled.div`
  background: #FFFFFF;
  flex: 0 0 100%;
  min-width: 0;
`
const MainImage = styled(GatsbyImage)`
  width: 100%;
  aspect-ratio: 1;
  max-height: calc(100vh - 213px);
  @media (max-width: 763px) {
    max-height: 100vw;
    min-height: 100vw;
  }
  mix-blend-mode: darken;

  img {
    opacity: 1 !important; /* Override the default opacity */
  }
}
`
const CampaignRibbon = styled.div`
  color: ${(props) => (props.textColor ? props.textColor : '#F3F3F3')};
  background-color: ${(props) => (props.bgColour ? props.bgColour : 'black')};
  text-align: center;
  font-size: 13px;
  padding: 4px 0;
  @media (max-width: 763px) {
    bottom: 0px;
  }
`
const MainVideo = styled(Video)`
  width: 100%;
  aspect-ratio: 1;
  max-height: calc(100vh - 213px);
  @media (max-width: 763px) {
    max-height: 100vw;
    min-height: 100vw;
  }
`
const GalleryContainer = styled.div`
  position: relative;
  background: #FFFFFF;
  max-width: calc(55vw - 32px);
  flex-grow: 1;
  margin: 0;
  margin-top: 0;
  margin-bottom: 0;
  user-select: none;
  padding-bottom: 0;
  @media (max-width: 763px) {
    max-width: 100vw;
    height: auto;
    flex-grow: auto;
    margin: 0px;
    padding-bottom: 0px;
  }
`
const Gallery = styled.div`
  background: #FFFFFF;
  position: relative;
  @media (min-width: 763px) {
    position: sticky;
    top: 113px;
    width: 100%;
    max-height: calc(100vh - 130px);
  }
  @media (max-width: 763px) {
    position: relative;
    width: 100%;
    padding: 0px;
  }

  .slick-next {
    right: 25px;
    color: black;
    z-index: 99999;
  }
  .slick-prev {
    left: 25px;
    color: black;
    z-index: 99999;
  }
  .slick-prev::before, .slick-next::before {
    color: black;
  }
`
const MainGalleryContainer = styled.div`
  position: relative;
`
const BuildYourBundleContainer = styled.div`
  position: absolute;
  right: 16px;
  bottom: 42px;
  @media (max-width: 763px) {
    bottom: auto;
    top: 16px;
  }
`
const BuildYourBundleButton = styled.div`
  border: 1px solid black;
  border-radius: 64px;
  padding: 8px 16px;
  padding-top: 11px;
  background: black;
  color: white;
  cursor: pointer;
  line-height: 1.6;
  font-size: 14px;
  transition: all 0.2s ease-out;

  &:hover {
    // border: 2px solid black;
    background: white;
    color: black;
  }

  @media (max-width: 763px) {
    font-size: 12px;

  }
`

const Embla = styled.div`
  overflow: hidden;
`

const EmblaContainer = styled.div`
  display: flex;
`

const EmblaPreviewContainer = styled.div`
  display: flex;
  padding: 0 3%;
  gap: 3%;
  justify-content: space-between;
`
const HeartIcon = styled.img`
  position: absolute;
  bottom: 80px;
  z-index: 2;
  right: 32px;
  height: 32px;

  @media (max-width: 763px) {
    bottom: 48px;
  }
`

const GallerySection = function ({
  images,
  thumbnails,
  selectedColor,
  setImage,
  defaultImage,
  changeImageTo,
  product_categories,
  disablePersonalisation,
  discount,
  pdpRibbon,
  discountPromoOn,
  productId,
  showBuildSet,
  hideRibbon,
}) {
  const { RibbonText, RibbonColour, RibbonTextColour, productPageOn, NonPersRibbon } = pdpRibbon

  const [selectedImage, setSelectedImage] = useState(0)
  const [loaded, setLoaded] = useState(false)

  const [emblaRef, emblaApi] = useEmblaCarousel({
    slidesToScroll: 1,
    loop: true,
  });
  const [emblaPreviewRef, emblaPreviewApi] = useEmblaCarousel({
    loop: true,
    watchSlides: true,
    slidesToScroll: 1,
    align: 'center'
  });
  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = EmBlaCarouselArrowButtons(emblaApi)

  useEffect(() => {
    if (setImage) {
      emblaApi && emblaApi.scrollTo(setImage);
    }
  }, [setImage, emblaApi]);

  const handleSlideChange = useCallback(
    (index) => {
      setSelectedImage(index);
      if (emblaPreviewApi) {
        emblaPreviewApi.scrollTo(index % images.length);
      }
    },
    [images.length, emblaPreviewApi]
  );

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('select', () => {
        const index = emblaApi.selectedScrollSnap();
        handleSlideChange(index);
      });
    }
  }, [emblaApi, handleSlideChange]);

  useEffect(() => {
    if (emblaPreviewApi) {
      emblaPreviewApi.on('select', () => {
        const index = emblaPreviewApi.selectedScrollSnap();
        handleSlideChange(index);
      });
    }
  }, [emblaPreviewApi, handleSlideChange]);

  const goNext = useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollNext();
    }
  }, [emblaApi]);

  const goPrev = useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollPrev();
    }
  }, [emblaApi]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      switch (e.key) {
        case 'ArrowRight':
          goNext();
          break;
        case 'ArrowLeft':
          goPrev();
          break;
        default:
          break;
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    }
  }, [goNext, goPrev]);

  const swipeTo = useCallback(
    (index) => {
      if (emblaApi) {
        setSelectedImage(index);
        emblaApi.scrollTo(index);
        if (emblaPreviewApi) {
          emblaPreviewApi.scrollTo(index % thumbnails.length);
        }
      }
    },
    [emblaApi, emblaPreviewApi, thumbnails.length]
  );

  useEffect(() => {
    if (changeImageTo !== undefined) {
      setSelectedImage(changeImageTo)
      swipeTo(changeImageTo)
    }
  }, [changeImageTo, swipeTo])

  useEffect(() => {
    if (!loaded) {
      setTimeout(() => {
        setLoaded(true)
      }, 500)
    } else if (selectedImage < defaultImage) {
      setSelectedImage(defaultImage)
      swipeTo(defaultImage)
    }
  }, [selectedColor, loaded, defaultImage, selectedImage, swipeTo])

  const imageComponents = useMemo(() => images.map((item, index) => {
    const imageUrl = item?.url;
    const url = new URL(imageUrl);
    const filename = url.pathname.split('/').pop();
    return (
      <ImageWrapper key={imageUrl} className="embla__slide">
        {item.mime === 'video/mp4' ? (
          <MainVideo
            src={item.file.publicURL}
            alt={item?.alternativeText || filename}
          />
        ) : (
          <MainImage
            loading={index === selectedImage ? 'eager' : 'lazy'}
            objectFit="contain"
            image={withArtDirection(
              getImage(item?.file?.childImageSharp?.gatsbyImageData),
              [
                {
                  media: '(max-width: 1024px)',
                  image: getImage(item?.file?.childImageSharp?.gatsbyImageDataMobile),
                },
              ]
            )}
            alt={(item?.alternativeText !== '' && item?.alternativeText !== null && item?.alternativeText !== undefined ? item?.alternativeText : filename) || "Product Image"}
          />
        )}
      </ImageWrapper>
    )
  }), [images, selectedImage]);

  const thumbnailComponents = useMemo(() => thumbnails.map((image, index) => (
    <div key={image.url}>
      {image.mime === 'video/mp4' ? (
        <DisplayImageConatiner
          selected={selectedImage === index}
          style={{ flexWrap: 'wrap' }}
          onClick={() => swipeTo(index)}
        >
          <DisplayVideo objectFit="contain" loading="eager" src={image.file.publicURL} />
        </DisplayImageConatiner>
      ) : (
        <DisplayImageConatiner
          selected={selectedImage === index}
          style={{ flexWrap: 'wrap' }}
          onClick={() => swipeTo(index)}
        >
          <DisplayImage
            objectFit="contain"
            loading="eager"
            image={getImage(image?.file?.childImageSharp?.gatsbyImageData)}
            alt={(image?.alternativeText || image?.name) || "Product IMage"}
          />
        </DisplayImageConatiner>
      )}
    </div>
  )), [thumbnails, selectedImage, swipeTo]);

  return (
    <GalleryContainer>
      <Gallery>
        <MainGalleryContainer>
          {/* {favoriteProducts.includes(productId) ? (
            <HeartIcon
              onClick={() => removeFavoriteProduct(productId)}
              src={Heart}
              alt="heart"
            />
          ) : (
            <HeartIcon
              onClick={() => addFavoriteProduct(productId)}
              src={EmptyHeart}
              alt="empty heart"
            />
          )} */}
          <Embla ref={emblaRef}>
            <EmblaContainer >
              {imageComponents}
            </EmblaContainer>
          </Embla>
          {
            hideRibbon !== true && <>
              {
                (discountPromoOn && discount?.amount > 0)? (
                  <CampaignRibbon bgColour={RibbonColour} textColor={RibbonTextColour}>{`${discount.percentage}% off for a limited time.`}</CampaignRibbon>
                ) : (
                  <>
                    {NonPersRibbon && productPageOn && !product_categories?.includes('Gift Card') ? (
                      <CampaignRibbon bgColour={RibbonColour} textColor={RibbonTextColour}> {RibbonText} </CampaignRibbon>
                    ) : (product_categories?.includes('Personalised') && productPageOn && (
                      <CampaignRibbon bgColour={RibbonColour} textColor={RibbonTextColour}> {RibbonText} </CampaignRibbon>
                    ))}
                  </>
                )
              }
            </>
          }
          {
            showBuildSet && (
              <BuildYourBundleContainer>
                <Link to='/build-your-set'>
                  <BuildYourBundleButton>
                    BUILD YOUR SET
                  </BuildYourBundleButton>
                </Link>
              </BuildYourBundleContainer>
            )
          }
        </MainGalleryContainer>
        <PrevButton  style={{left: '3%',top: '43%'}} onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
        <NextButton  style={{right: '3%',top: '43%'}} onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        <DisplayImageContainer>
          <Embla ref={emblaPreviewRef}>
            <EmblaPreviewContainer>
              {thumbnailComponents}
            </EmblaPreviewContainer>
          </Embla>
        </DisplayImageContainer>
      </Gallery>
    </GalleryContainer >
  )
}

export default GallerySection
